import { createSelector } from 'reselect';
import Debug from 'debug';

import { selectUserCountry, selectUserCanEditItem } from '../session/selectors';
import { toLocalLinks } from '~/app/lib/utils/resolveLinksForCountry';
import { MappedArtist } from '../../songwhipApi/mapper';
import hasLinksForCountry from '~/app/lib/utils/hasLinksForCountry';
import isVariousArtists from '~/app/lib/utils/isVariousArtists';
import { resolveError } from '~/app/lib/errors/utils';
import { ItemConfig } from '../../songwhipApi/types';
import isStale from '~/app/lib/utils/isStale';
import { ItemTypes } from '~/types';
import resolveItemImage from '../lib/resolveItemImage';
import { selectPrimaryOwnerAccount } from '../lib/selectors';
import { StatusTypes, State } from '../types';

import { SelectedArtist, StoredArtist } from './types';

const debug = Debug('songwhip/redux/artist/selectors');

export const selectArtistStateItem = (state: State, artistId: number) =>
  state.artists[artistId];

export const selectArtistValue = (state: any, artistId: number) =>
  selectArtistStateItem(state, artistId)?.value;

export const createSelectArtist = (
  selectStateItem: typeof selectArtistStateItem = selectArtistStateItem
) => {
  const selectArtistValue = (state: State, artistId: number) =>
    selectStateItem(state, artistId)?.value;

  const createSelectKey =
    <TKey extends keyof StoredArtist>(key: TKey) =>
    (state: State, artistId: number) =>
      selectArtistValue(state, artistId)?.[key];

  const selectArtistIsLoading = (state: State, artistId: number) => {
    const item = selectStateItem(state, artistId);
    return !!item && item.status === StatusTypes.PENDING;
  };

  const selectUserCanEditArtist = (state: State, artistId: number) =>
    selectUserCanEditItem(state, selectArtistValue(state, artistId));

  const selectArtistError = (state: State, artistId: number) => {
    const artist = selectStateItem(state, artistId);
    return resolveError(artist?.error);
  };

  // PERF: We select just the properties we need. Reselect will `===` check the result
  // of each of the selectors below. If ANY of them are different from the previous
  // result the cached value of the output is discarded and a new `SelectedArtist`
  // output. If we blindly select the root
  return createSelector(
    [
      createSelectKey('id'),
      createSelectKey('path'),
      createSelectKey('pagePath'),
      createSelectKey('name'),
      createSelectKey('spotifyId'),
      createSelectKey('description'),
      createSelectKey('config'),
      createSelectKey('links'),
      createSelectKey('linksCountries'),
      createSelectKey('sourceCountry'),
      createSelectKey('refreshedAtTimestamp'),
      createSelectKey('updatedAtTimestamp'),
      selectUserCountry,
      selectUserCanEditArtist,
      createSelectKey('image'),
      selectArtistError,
      selectArtistIsLoading,
      createSelectKey('ownedByAccounts'),
      createSelectKey('customLinks'),
      createSelectKey('albums'),
      createSelectKey('videos'),
      createSelectKey('shows'),
    ],
    // this output function is only called when the
    // result of ANY of the above changes
    (
      id,
      path,
      pagePath,
      name,
      spotifyId: string,
      description: string | undefined,
      config: ItemConfig | undefined,
      links,
      linksCountries,
      sourceCountry: string,
      refreshedAtTimestamp,
      updatedAtTimestamp,
      userCountry,
      userCanEdit,
      originalImage,
      error,
      isLoading: ReturnType<typeof selectArtistIsLoading>,
      ownedByAccounts: MappedArtist['ownedByAccounts'] | undefined,
      customLinks: MappedArtist['customLinks'],
      albums,
      videos,
      shows
    ): SelectedArtist | undefined => {
      if (!id) return;

      const ownerAccount = selectPrimaryOwnerAccount(ownedByAccounts);

      const result: SelectedArtist = {
        type: ItemTypes.ARTIST,
        id,
        pagePath: pagePath!,
        name: name!,
        image: resolveItemImage({ image: originalImage, config }),
        originalImage,
        config,
        isStale: isStale(refreshedAtTimestamp),
        isOwned: !!ownedByAccounts?.length,
        description,
        isShallow: !links,
        userCanEdit,
        error,
        pageBrand: ownerAccount?.orchardBrand,
        ownedByAccountIds: ownedByAccounts?.map(({ id }) => id),
        primaryOwnerAccount: ownerAccount,
        customLinks,
        isLoading,
        spotifyId,
        isVariousArtists: isVariousArtists(path!),
        links: toLocalLinks({ links, sourceCountry }, userCountry),
        updatedAtTimestamp,
        hasLinksForUserCountry: hasLinksForCountry(
          { linksCountries },
          userCountry
        ),

        albums,
        videos,
        shows,
      };

      debug('new SelectedArtist', result);

      return result;
    }
  );
};

/**
 * A general purpose artist selector.
 *
 * For most cases this is fine to use unless you are using it to render
 * multiple artists on the same page. This is because it only has a cache
 * size of 1, meaning as soon as you pass a different `artistId` it'll
 * can't reuse the previous memoized result. The result of this is that
 * you'll end up exposing a new object to react views on each render
 * meaning a lot of extra react render cycles and slow performance.
 *
 * A rule of thumb is to use an instance of `selectArtist()` per artist
 * per page. So if you were rendering a list of Artists it would be best
 * to have an instance of `selectArtist()` per list item, so that when
 * react re-renders the page, each list item will get the same memoized
 * result as the previous render and react can skip re-rendering each
 * list item.
 *
 * In practice these case are rare. Most Album/Track pages just need to
 * select the main-artist so selectArtistGlobal() is fine for this as
 * when we change pages the react tree will be rebuilt and caches
 * will be lost anyway, but for the lifetime of that page we can be
 * sure that we'll be using memoized SelectedArtist for the main-artist
 * at least. Isolated components in the view that need to select the
 * same Artist will also have the benefit of getting the memoized objects.
 *
 * @example
 * const selectArtist = useMemo(() => createSelectArtist());
 *
 * Doing this will ensure resulting objects are memoized and won't invalidate
 * React views causing needless re-renders.
 */
export const selectArtistGlobal = createSelectArtist();
