import { createSelector } from 'reselect';

import { toLocalLinks } from '~/app/lib/utils/resolveLinksForCountry';
import hasLinksForCountry from '~/app/lib/utils/hasLinksForCountry';
import { resolveError } from '~/app/lib/errors/utils';
import isStale from '~/app/lib/utils/isStale';
import { ItemTypes } from '~/types';

import { selectUserCanEditItem, selectUserCountry } from '../session/selectors';
import { selectPrimaryOwnerAccount } from '../lib/selectors';
import { selectArtistGlobal } from '../artists/selectors';
import resolveItemImage from '../lib/resolveItemImage';
import { StatusTypes, State } from '../types';

import { SelectedTrack, StoredTrack } from './types';

const selectTrackStateItem = (state: State, trackId: number) =>
  state.tracks[trackId];

export const selectTrackValue = (state: State, trackId: number) =>
  selectTrackStateItem(state, trackId)?.value;

/**
 * Create a selector function that takes the redux State object
 * and an `trackId` and returns a composed SelectedTrack. This
 * is nice because we can keep the underlying `MappedTrack`
 * largely similar to the `songwhip-api` payload but reshape
 * the `SelectedTrack` to better match the requirements of
 * the songwhip-web client.
 *
 * By default the result is derived from the `state.tracks` store,
 * but this can be overridden via the `selectStateItem` argument.
 * This is used in the 'edit mode' when we want to derive state
 * from the 'staged' Track clone in `state.editStage`.
 *
 * Reselect is used to ensure we memo-ize the result and only return
 * a new object when select parts of the underlying input state change.
 */
export const createSelectTrack = ({
  selectStateItem = selectTrackStateItem,
  selectArtist = selectArtistGlobal,
}: {
  selectStateItem?: typeof selectTrackStateItem;

  /**
   * COMPLEX: By default we're using selectArtistGlobal instead of invoking
   * createSelectArtist() as multiple instances of selectAlbum() cannot reuse
   * the same SelectedArtist memo cache. If you ever need to render multiple
   * Tracks per page, you'll want a dedicated `selectAlbum` instance for each
   * and should pass a dedicated `selectArtist` instance to createSelectAlbum()
   * to ensure that the (1 entry) memo cache isn't being shared between all.
   */
  selectArtist?: typeof selectArtistGlobal;
} = {}) => {
  const selectValue = (state: State, trackId: number) =>
    selectStateItem(state, trackId)?.value;

  const selectIsLoading = (state: State, trackId: number) =>
    selectStateItem(state, trackId)?.status === StatusTypes.PENDING;

  const selectMainArtist = (state: State, trackId: number) => {
    const artistIds = selectValue(state, trackId)?.artistIds;
    const mainArtistId = artistIds?.[0];

    return mainArtistId ? selectArtist(state, mainArtistId) : undefined;
  };

  const selectError = (state: State, trackId: number) => {
    const track = selectStateItem(state, trackId);
    return resolveError(track?.error);
  };

  const selectUserCanEdit = (state: State, trackId: number) =>
    selectUserCanEditItem(state, selectValue(state, trackId));

  const createSelectKey =
    <TKey extends keyof StoredTrack>(key: TKey) =>
    (state: State, trackId: number) =>
      selectValue(state, trackId)?.[key];

  // PERF: Reselect is used to ensure that a new object is only output when
  // any of the core 'input selectors' return a DIFFERENT value. It's important
  // that the values returned from these function are as tightly scoped as possible.
  return createSelector(
    [
      createSelectKey('id'),
      createSelectKey('pagePath'),
      createSelectKey('name'),
      createSelectKey('config'),
      createSelectKey('image'),
      createSelectKey('links'),
      createSelectKey('linksCountries'),
      createSelectKey('sourceCountry'),
      createSelectKey('ownedByAccounts'),
      createSelectKey('artistIds'),
      createSelectKey('refreshedAtTimestamp'),
      createSelectKey('updatedAtTimestamp'),
      createSelectKey('customLinks'),
      selectMainArtist,
      selectUserCountry,
      selectUserCanEdit,
      selectError,
      selectIsLoading,
    ],
    // this output function is only called when the
    // result of ANY of the above changes
    (
      id,
      pagePath,
      name,
      config,
      image,
      links,
      linksCountries,
      sourceCountry,
      ownedByAccounts,
      artistIds,
      refreshedAtTimestamp,
      updatedAtTimestamp,
      customLinks,
      mainArtist,
      userCountry,
      userCanEdit,
      error,
      isLoading
    ): SelectedTrack | undefined => {
      if (!id) return;
      if (!mainArtist) return;

      const primaryOwnerAccount = selectPrimaryOwnerAccount(ownedByAccounts);

      const result: SelectedTrack = {
        type: ItemTypes.TRACK,
        id,
        name: name!,
        pagePath: pagePath!,
        artistId: mainArtist.id,
        artistName: mainArtist.name,
        artistPagePath: mainArtist.pagePath,
        artistIds: artistIds!,
        config,
        updatedAtTimestamp,
        links: toLocalLinks({ links, sourceCountry }, userCountry),

        hasLinksForUserCountry: hasLinksForCountry(
          { linksCountries },
          userCountry
        ),

        image: resolveItemImage({ config, image }),
        originalImage: image,

        // The item needs to have an account with an active subscription to be `owned`.
        // Note: Orchard accounts always have an active subscription
        isOwned: !!ownedByAccounts?.length,
        pageBrand: primaryOwnerAccount?.orchardBrand,

        isStale: isStale(refreshedAtTimestamp),
        isShallow: !links,
        userCanEdit,
        error,
        isLoading,

        // used by the custom domains scoping feature to check if pages are in an account scope
        ownedByAccountIds: ownedByAccounts?.map(({ id }) => id),

        primaryOwnerAccount,
        customLinks,
      };

      return result;
    }
  );
};

export const selectTrackGlobal = createSelectTrack();
